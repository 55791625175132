import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import ServiceTable from "../../Components/Table/ServiceTable";
function Service() {
  
    return (
      <div className='max-w-lg mx-auto bg-black'>
      <Header/>
          <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
              <ServiceTable/>
          </div>
      <Footer/>
    </div> 
      
    );
  }
    
    export default Service;
    
  