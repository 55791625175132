import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyTable() {
  
    return (
        <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th className='text-left text-lg'>개인정보처리방침</th>
                    </tr>
                    <tr>
                        <td> <Link to="/PrivacyPage?PrivacyDate=20250124">개인정보처리방침_20250124</Link></td>
                    </tr>
                    <tr>
                        <td> <Link to="/PrivacyPage?PrivacyDate=20231201">개인정보처리방침_20231201</Link></td>
                    </tr>
                </thead>
            </table>
        </div> 
      
    );
  }
    
    export default PrivacyTable;
    
  