import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import PrivacyTable from "../../Components/Table/PrivacyTable";

function Privacy() {
  
    return (
      
    <div className='max-w-lg mx-auto bg-black'>
        <Header/>
            <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
                <PrivacyTable/>
            </div>
        <Footer/>
      </div> 
      
    );
  }
    
    export default Privacy;
    
  