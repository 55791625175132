import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import PrivacyText_20231201 from "../../Components/Text/PrivacyText_20231201";
import PrivacyText_20250124 from "../../Components/Text/PrivacyText_20250124";

import { useSearchParams } from "react-router-dom";

function PrivacyPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    const PrivacyDateValue = searchParams.get("PrivacyDate");  
    const PrivacyText = () => {
        if(PrivacyDateValue === "20231201"){
            return (
                <PrivacyText_20231201/>
            )
        }
        else if(PrivacyDateValue === "20250124"){
            return (
                <PrivacyText_20250124/>
            )
        }
    }
    return (
      
    <div className='max-w-lg mx-auto bg-black'>
        <Header/>
            <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
                <PrivacyText/>
            </div>
        <Footer/>
      </div> 
      
    );
  }
    
    export default PrivacyPage;
    
  