import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import ServiceText_20231201 from "../../Components/Text/ServiceText_20231201";

import { useSearchParams } from "react-router-dom";

function PrivacyPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    const ServiceDateValue = searchParams.get("ServiceDate");  
    const ServiceText = () => {
        if(ServiceDateValue === "20231201"){
            return (
                <ServiceText_20231201/>
            )
        }
    }
    return (
      
    <div className='max-w-lg mx-auto bg-black'>
        <Header/>
            <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
                <ServiceText/>
            </div>
        <Footer/>
      </div> 
      
    );
  }
    
    export default PrivacyPage;
    
  