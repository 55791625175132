import React from 'react';
import { Link } from 'react-router-dom';

function ServiceTable() {
  
    return (
        <div className='mx-auto max-w-7xl px-5 py-5 text-white'>
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th className='text-left text-lg'>서비스이용약관</th>
                    </tr>
                    <tr>
                        <td> <Link to="/ServicePage?ServiceDate=20231201">서비스이용약관_20231201</Link></td>
                    </tr>
                </thead>
            </table>
        </div> 
      
    );
  }
    
    export default ServiceTable;
    
  