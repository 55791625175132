function PrivacyText() {


    const text =
    `
    주식회사 창꼬 개인정보처리방침

    주식회사 창꼬(이하 "회사"라고 함)은 통신비밀보호법, 전기통신사업법, 정보통신망 
    이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며,
    관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 주식회사 창꼬의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.
    
    주식회사 창꼬(이하 "회사"라 합니다)는 이용자들의 개인정보보호를 중요시하며, 
    이용자가 회사의 창꼬(https://chang-ggo.com) 서비스(이하 "서비스"라 합니다)를 이용함과 동시에 
    온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다. 
    이에 회사는 개인정보보호법에 따라 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하고 있습니다.
    회사는 개인정보처리방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 
    개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
    주식회사 창꼬 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.

    가. 개인정보의 수집 및 이용목적
    나. 개인정보 보유 및 이용 기간
    다. 개인정보의 처리위탁 
    라. 이용자의 권리(열람,정정,삭제 등)와 행사방법
    마. 개인정보보호를 위한 기술적-관리적 대책
    바. 개인정보 파기절차 및 방법
    사. 개인정보 보호책임자 및 담당부서 안내
    `

    return (
        <div className="w-full whitespace-pre-line">
            <div>
                {text}
            </div>
            
            <div className="mt-5">
                <h1>가. 개인정보의 수집 및 이용목적</h1>    
                <p className="my-3">- 서비스 이용시 필수 수집 항목</p>
                <table className="w-full">
                    <tr>
                        <th className="border border-white">수집 목적</th>
                        <th className="border border-white">수집 항목</th>
                        <th className="border border-white">보유 및 이용 기간</th>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            본인인증
                        </td>
                        <td className="border border-white">
                            이름, 성별, 생년월일, 휴대폰번호, CI/DI, 내/외국인정보, 본인인증결과    
                        </td>
                        <td className="border border-white" rowSpan={6}>
                            - 탈퇴 요청 5일 후 지체없이 파기 
                            - 내부 방침에 의해 서비스 부정이용 기록은 부정 가입 및 이용방지를 위하여 회원탈퇴 시점으로부터 1년간 보관 후 파기
                            - 단 관계 법령에 따라 일정기간 보존해야 하는 경우 해당 기간 보관후 파기
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            - 창꼬 회원 가입의사 확인 및 회원관리(회원제 서비스 제공) 
                            - 서비스의 변동 및 약관 변경 등의 고지를 위한 안내 
                            - 창꼬 서비스를 이용
                        </td>
                        <td className="border border-white">
                            - 아이디, 이메일, 비밀번호, 성별, 내/외국인정보, CI/DI
                            - 카카오를 통한 회원가입, 네이버를 통한 회원가입, 구글을 통한 회원가입, 로그인 정보 식별값, 성별, 생년월일, 휴대폰번호, 이메일, CI
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            간편 로그인
                        </td>
                        <td className="border border-white">
                            로그인정보 식별 값 (카카오, 네이버, 구글)
                            당사는 고객의 편의를 위해 간편 로그인 방식을 제공하고 있습니다.
                            이와 같은 로그인 시 당사가 고객의 개인정보를 추가 수집하지 않으며,
                            다만 로그인 정보 식별값만 비교하고 있습니다.
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            상품 구매
                        </td>
                        <td className="border border-white">
                            - 구매자 정보(이름, 이메일, 휴대폰번호, 전화번호, 주소) 
                            - 수령자 정보(이름, 이메일, 휴대폰번호, 전화번호, 주소)
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            마이페이지 배송지 등록
                        </td>
                        <td className="border border-white">
                            이름, 휴대폰번호, 전화번호, 주소
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            월말 평가, 오디션
                        </td>
                        <td className="border border-white">
                            이름, 휴대폰번호, 전화번호
                        </td>
                    </tr>
                </table>
            </div>
            <div className="mt-5">
                <p className="mt-3">- 민원 및 고충처리를 위한 필수 수집 항목</p>
                <p className="mb-3">민원인의 신원 확인, 민원 사항 확인, 사실 조사를 위한 연락 및 통지, 처리 결과 통보, 각종 고지 및 통지, 고충처리, 분쟁조정을 위한 수집</p>
                <table className="w-full">
                    <tr>
                        <th className="border border-white">수집 목적</th>
                        <th className="border border-white">수집 항목</th>
                        <th className="border border-white">보유 및 이용 기간</th>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            회원의 고충 처리를 위한 CS상담 처리
                        </td>
                        <td className="border border-white">
                            아이디, 이름, 성별, 휴대폰번호, 내/외국인정보, 주문번호, 주소
                        </td>
                        <td className="border border-white" rowSpan={2}>
                            관련 법령에 따라 안전하게 3년 보관 후 파기합니다.
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            제3자(수령인)의 고충 처리를 위한 CS상담 처리
                        </td>
                        <td className="border border-white">
                            수령인의 이름, 연락처, 주소
                        </td>
                    </tr>
                </table>
            </div>
            <div className="mt-5">
                <p className="my-3">- 선택 수집항목</p>
                <table className="w-full">
                    <tr>
                        <th className="border border-white">수집 목적</th>
                        <th className="border border-white">수집 항목</th>
                        <th className="border border-white">보유 및 이용 기간</th>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>마케팅 및 광고의 활용</p>
                            <p>- 이벤트 및 광고성 정보 제공 및 참여기회 제공</p>
                            <p>- 서비스의 유효성 확인</p>
                        </td>
                        <td className="border border-white">
                            개인식별정보: 이름, 성별, 나이, 휴대폰번호, 이메일, 고객 ID, 접속 일시, IP주소 
                            (수집∙이용항목은 마케팅 및 분석 목적에 따라 달라질 수 있으며 수집 시점에 안내 후 동의 여부를 확인함)
                        </td>
                        <td className="border border-white">
                            개인정보 수집 및 이용목적이 달성되면 지체없이 파기합니다.
                            (단, 관계 법령에 따라 일정 기간 보관해야 하는 항목은 해당 기간 보관 후 파기합니다.)
                        </td>
                    </tr>
                </table>
            </div>
            <div className="mt-5">
                <p className="my-3">- 회사는 아래와 같은 방법으로 개인정보를 수집합니다.</p>
                <ul>
                    <li>1) 웹페이지, 전화, 고객센터, 상담게시판, 이메일, 이벤트 응모</li> 
                    <li>2) 생성정보 수집 툴을 통한 수집</li>
                </ul>
            </div>
            <div className="mt-5">
                <p className="my-3">- 만 14세 미만 회원의 개인정보를 수집하지 않습니다.</p>
                <ul>
                    <li>※ 개인정보 제공은 창꼬 서비스 이용을 위해 필요한 사항입니다. 개인정보 제공을 거부할 경우 창꼬 서비스 이용에 제한될 수 있습니다.</li> 
                    <li></li>
                </ul>
            </div>
            <div className="mt-5">
                <h1>나. 개인정보 보유 및 이용 기간</h1>    
                <p className="my-3">이용자의 개인정보는 “바. 개인정보파기절차 및 방법”의 기준으로 탈퇴요청 5일 후 개인정보 수집 및 이용목적이 달성되면 지체없이 파기 합니다. 다만, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.</p>
                <ul>
                    <li>
                        - (전자상거래법) 계약 또는 청약철회 등에 관한 기록 : 5년
                    </li>
                    <li>
                        - (전자상거래법) 대금결제 및 재화등의 공급에 관한 기록 : 5년
                    </li>
                    <li>
                        - (전자상거래법) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                    </li>
                    <li>
                        -  (통신비밀보호법) 로그인 기록(로그기록, 접속지의 추적자료) : 3개월
                    </li>
                </ul>
                <p className="mt-3">회사는 1년간 서비스를 이용하지 않은 회원의 개인정보를 보호하기 위해 휴면 회원으로 전환하여 별도로 분리 보관합니다.</p>
            </div>
            <div className="mt-5">
                <h1>다. 개인정보의 처리위탁</h1>    
                <p className="my-3">회사는 서비스 제공을 위하여 필요한 업무 중 일부를 아래와 같이 외 업체에 위탁하고 있으며, 관계 법률에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고, 이를 준수하도록 관리 감독하고 있습니다.</p>
                <table className="w-full">
                    <tr>
                        <th className="border border-white">수탁 업체</th>
                        <th className="border border-white">위탁업무 내용</th>
                        <th className="border border-white">보유 및 이용 기간</th>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>토스페이먼츠(주)</p>
                        </td>
                        <td className="border border-white">
                            결제대행사
                        </td>
                        <td className="border border-white" rowSpan={3}>
                            회원 탈퇴 시 또는 위탁 업무 종료 시(관계법령의 규정에 의하여 보존할 필요가 있는 경우 및 사전 동의를 받은 경우 해당 보유 기간)
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>(주)드림토이</p>
                        </td>
                        <td className="border border-white">
                            상품 배송포장 대행
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>(주)한진</p>
                        </td>
                        <td className="border border-white">
                            상품 배송 대행
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>(주)케이지이니시스</p>
                        </td>
                        <td className="border border-white">
                            본인인증
                        </td>
                        <td className="border border-white">
                            회원 탈퇴 시 
                        </td>
                    </tr>
                </table>
                <p className="my-3">인프라제공</p>
                <table className="w-full">
                    <tr>
                        <th className="border border-white">수탁 업체</th>
                        <th className="border border-white">위탁업무 내용</th>
                        <th className="border border-white">보유 및 이용 기간</th>
                    </tr>
                    <tr>
                        <td className="border border-white">
                            <p>Amazon Web Service Inc</p>
                        </td>
                        <td className="border border-white">
                            인프라 제공
                        </td>
                        <td className="border border-white" rowSpan={2}>
                            회원 탈퇴 시 또는 위탁 계약 종료 시까지
                        </td>
                    </tr>
                </table>
            </div>
            <div className="mt-5">
                <h1>라. 이용자의 권리(열람,정정,삭제,처리정지)와 행사방법</h1>    
                <ul>
                    <li>
                        이용자는 언제든지 창꼬 홈페이지의 마이페이지에서 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제할 수 있으며, 가입 해지를 요청할 수 있습니다.
                    </li>
                    <li>
                        이용자는 ‘회원정보변경’ 페이지에서 아이디를 제외한 모든 입력사항을 수정할 수 있습니다.
                    </li>
                    <li>
                        이용자는 ‘회원정보변경’ 페이지에서 ‘회원탈퇴’를 통해 개인정보 수집 및 이용 동의를 철회할 수 있으며 회원정보는 "나. 개인정보의 보유 및 이용기간"항에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리됩니다.
                    </li>
                    <li>
                        이용자는 및 개인정보 보호책임자에게 서면, 전화 또는 이메일로 요청할 수 있으며 지체없이 조치하겠습니다.
                    </li>
                    <li>
                        이용자는 법정대리인이나 위임을 받은 자 등 대리인을 통하여 권리행사를 할 수도 있으며, 이 경우 「개인정보 처리 방법에 관한 고시(제2020-7호)」 [별지 제11호] 서식에 따른 위임장을 제출하셔야 합니다.
                    </li>
                    <li>
                        정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                    </li>
                </ul>
            </div>
            <div className="mt-5">
                <h1>마. 개인정보보호를 위한 기술적-관리적 보호 대책</h1> 
                <p className="my-3">회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</p>   
                <ul>
                    <li>
                        1. 개인정보 암호화
                    </li>
                    <li>
                        이용자의 중요 개인정보는 암호화하여 저장/관리되고 있으며, 암호화통신을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 비밀번호는 복호화가 불가능한 일방향 암호화되어 저장/관리되고 있습니다
                    </li>
                    <li>
                        2. 해킹 등에 대비한 대책
                    </li>
                    <li>
                        회사는 해킹이나 악성코드 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 통제된 구역에 침입탐지를 운영하여 24시간 감시하고 있습니다.
                    </li>
                    <li>
                        3. 개인정보 취급자의 최소화 및 교육실시
                    </li>
                    <li>
                        개인정보를 처리하는 직원을 최소화 하며, 개인정보 유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
                    </li>
                </ul>
            </div>
            <div className="mt-5">
                <h1>바. 개인정보 파기절차 및 방법</h1>  
                <p className="my-3">이용자의 개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기되며, 파기 절차 및 방법은 아래의 기준에 의해 관리됩니다.</p>  
                <ul>
                    <li>
                        (1) 파기절차
                    </li>
                    <li>
                        - 회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다.
                    </li>
                    <li>
                        (2) 파기방법
                    </li>
                    <li>
                        - 전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.
                    </li>
                 </ul>
            </div>
            <div className="mt-5">
                <h1>사. 개인정보 보호책임자 및 담당부서 안내</h1>  
                <p className="my-3">회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충 처리를 위하여 다음과 같이 책임자와 담당부서를 운영하고 있습니다.</p>  
                <ul>
                    <li>
                        개인정보 보호책임자 : 송시헌 (총괄 이사)
                    </li>
                    <li>
                        연락처: 070-7782-0309
                    </li>
                    <li>
                        이메일: sheon@chang-ggo.com
                    </li>
                 </ul>
                 <ul className="my-3">
                    <li>
                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                    </li>
                    <li>
                        - 개인정보침해신고센터 : http://privacy.kisa.or.kr / (국번없이)118
                    </li>
                    <li>
                        - 대검찰청 사이버수사과 : http://spo.go.kr / (국번 없이) 1301
                    </li>
                    <li>
                        - 경찰청 사이버수사국 : https://ecrm.cyber.go.kr/minwon/main (국번 없이) 182
                    </li>
                    <li>
                        - 개인정보분쟁조정위원회 : http://kopico.go.kr / 1833-6972
                    </li>
                 </ul>
                 <ul>
                    <li>부칙</li>
                    <li>이 개인정보처리방침은 2025년 01월 24일에 개정되었으며 정부의 정책 및 정보보호 유관법령 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 공지사항을 통해 고지할 것입니다.</li>
                    <li>- 개인정보 처리방침 시행일자 : 2025년 01월 24일</li>
                 </ul>
            </div>

       </div>


    );
}

export default PrivacyText;
