import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
export default function EventDialog(props) {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    useEffect(() => {
        const savedValue = localStorage.getItem('doNotShowAgain');
        if (savedValue) {
            setDoNotShowAgain(JSON.parse(savedValue));
            onClickNo()
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setDoNotShowAgain(isChecked);
        localStorage.setItem('doNotShowAgain', JSON.stringify(isChecked));
    };

    const onClickYes = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.setOpen(false);
    };

    const onClickNo = () =>{
        props.setOpen(false)    
    }


  return (
    <div>
      <Dialog
        open={props.open}
        onClose={onClickNo}
      >
        <DialogTitle id="customized-dialog-title" >
          <div className='flex justify-between items-center'>
          {props.title}
          <IconButton aria-label="close" onClick={onClickNo}><CloseIcon /></IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <form >
              <div className='flex flex-col gap-2'>
            <label  className="font-medium text-gray-700 block"> 
              {props.contents}
            </label>
            <Link to="/PrivacyPage?PrivacyDate=20250124">개인정보처리방침_20250124</Link>  
            <div className='flex items-center mt-2'>
                <input
                    type="checkbox"
                    id="doNotShowAgain"
                    checked={doNotShowAgain}
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="doNotShowAgain" className="ml-2 text-sm text-gray-600">
                    알림 띄우지 않기
                </label>
            </div>
            </div>
            </form>
          </div>

        </DialogContent>
        <DialogActions>

        
        </DialogActions>
      </Dialog>
    </div>
  );
}
EventDialog.defaultProps = {
  title : "개인정보처리방침 개정 안내 (20231201 -> 20250124)",
  contents : "(주)창꼬는 개인정보 처리방침의 내용이 다음과 같이 변경됨을 알려드립니다.",
  yesBtnName : "Yes",
  noBtnName : "no",
  yesBtnClassName : "border border-Cgcolor rounded-lg py-3 px-3 text-Cgcolor font-bold mr-2",
  noBtnClassName : "border border-black rounded-lg py-3 px-3 text-black font-bold mr-2 focus:outline-none mr-1 ease-linear transition-all duration-150"

}